<div class="model-popup-wrapper profit-course add-new-course-popup dashboard-popup">
  <div class="model-popup-inner">
    <div class="model-popup">
      <div class="model-popup-title d-flex mb-4">
        <h3>Navigation</h3>
        <button type="button" class="btn-close" (click)="closePopup(false)" aria-label="Close"></button>
      </div>
      <div class="question-bottom-wrapper">
        <div class="question-bottom-inner">
          <div class="dashboard-content d-flex flex-wrap align-items-start">
            <ng-container *ngIf="isProfitAdviser">
              <div class="dashboard-box" *ngFor="let routeName of routes">
                <a href="javascript:void(0);">
                  <img [src]="'assets/images/dashboard/' + imageMap[routeName]" [alt]="routeName" (click)="goToImageDetail(routeName)" />
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="isAdmin && isNotAgent">
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToAssessment()" [routerLink]="['/pages/assessment']">
                  <img src="/assets/images/dashboard/assessment.png" alt="assessment" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToQuestionConfig()" [routerLink]="['/pages/questionconfig']">
                  <img src="/assets/images/dashboard/question-config.png" alt="question-config" />
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="isAdmin && !isNotAgent">
              <div class="dashboard-box" *ngFor="let routeName of adminRoutes">
                <a href="javascript:void(0);">
                  <img [src]="'assets/images/dashboard/' + adminMap[routeName]" [alt]="routeName" (click)="goToImageDetail(routeName)" />
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="isAgent">
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToAssessment()" [routerLink]="['/pages/assessment']">
                  <img src="/assets/images/dashboard/assessment.png" alt="assessment" />
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
