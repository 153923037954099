import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Storage } from 'src/app/Core/storage';
import { UserRoleEnum } from 'src/app/Core/userRole.enum';
import { IUser } from 'src/app/Models/user.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-popup',
  templateUrl: './dashboard-popup.component.html',
  styleUrls: ['./dashboard-popup.component.css']
})
export class DashboardPopupComponent implements OnInit {
  private modelRef: NgbModalRef = {} as NgbModalRef;
  public userData: IUser = {} as IUser;
  isProfitAdviser: boolean = false;
  isNotAgent: boolean = false;
  isAgent: boolean = false;
  isAdmin: boolean = false;
  imageMap: { [key: string]: string } = {
    'assessment': 'assessment.png',
    'brandNewClass': 'brand-new.png',
    'agentProfitPlan': 'classes-to-teach.png',
    'agentProfitScript': 'scripts.png',
    'agentProfitUses': 'how-to-use-the-system.png',
    'weeklyPlans': 'your-biz-plan.png',
    'previousCoachingCalls': 'previous-calls.png',
    'magazine': 'publish-your-magazine.png',
    'commercialLoans': 'commercial-loans.png',
    'chatGpt': 'chatgpt.png',
    'publishYourBook': 'publish-your-book.png',
    'socialMediaPost': 'social-media-post.png',
    'howtohostevent': 'how-to-host-event.png',
    'popbyideas': 'popbyideas.jpg',
    'messageboard': 'message-board.png',
    'textingcampaigns': 'texting-campaigns.jpg'
  };

  isNotAgentMap: { [key: string]: string } = {
    'assessment': 'assessment.png',
    'questionconfig': 'question-config.png',
  }

  adminMap: { [key: string]: string } = {
    'profitofficer': 'view-your-advicer.png',
    'questionconfig': 'question-config.png',
    'brandNewClass': 'brand-new.png',
    'agents': 'view-your-agent.png',
    'unarchive': 'archive.png',
    'agentProfitPlan': 'classes-to-teach.png',
    'agentProfitScript': 'scripts.png',
    'agentProfitUses': 'how-to-use-the-system.png',
    'weeklyPlans': 'your-biz-plan.png',
    'previousCoachingCalls': 'previous-calls.png',
    'magazine': 'publish-your-magazine.png',
    'commercialLoans': 'commercial-loans.png',
    'chatGpt': 'chatgpt.png',
    'publishYourBook': 'publish-your-book.png',
    'socialMediaPost': 'social-media-post.png',
    'howtohostevent': 'how-to-host-event.png',
    'popbyideas': 'popbyideas.jpg',
    'messageboard': 'message-board.png',
    'textingcampaigns': 'texting-campaigns.jpg',
  };
  routes: string[] = Object.keys(this.imageMap);
  isNotAgentRoutes: string[] = Object.keys(this.isNotAgentMap);
  adminRoutes: string[] = Object.keys(this.adminMap);
  constructor(private router: Router) { }

  async ngOnInit() {
    this.userData = (await Storage.getUserData()) ?? ({} as IUser);
    if (this.userData.role === UserRoleEnum.ProfitAdviser) {
      this.isProfitAdviser = true;
    } else if (this.userData.role == UserRoleEnum.Admin) {
      this.isAdmin = true;
      if (!environment.isAgent) {
        this.isNotAgent = true;
      } else {
        this.isNotAgent = false;
      }
    } else {
      this.isAgent = true;
    }
  }

  public static open(modalService: NgbModal) {
    var ref = modalService.open(DashboardPopupComponent);
    ref.componentInstance.modelRef = ref;
    return ref;
  }

  closePopup(isSubmitted: boolean = false) {
    this.modelRef.close({
      isSubmitted: isSubmitted
    });
  }

  redirectToAssessment() {
    this.modelRef.close({
      isSubmitted: false
    });
  }

  redirectToQuestionConfig() {
    this.modelRef.close({
      isSubmitted: false
    });
  }

  goToImageDetail(imageName: string) {
    this.router.navigate(['/pages/', imageName]);

    this.modelRef.close({
      isSubmitted: false
    });
  }
}
