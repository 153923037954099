import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IUser} from 'src/app/Models/user.interface';
import {environment} from 'src/environments/environment';
import {apiRoutes} from '../Core/apiRoutes.enum';
import {IAssessmentUserInfo} from '../Models/IAssessmentUserInfo';
import {LoaderService} from '../shared/Services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private baseApiUrl = environment.baseApiUrl;

  private storedNumber: any = '';

  constructor(private http: HttpClient, private loader: LoaderService) {}

  public login(email: string, password: string) {
    return this.http.post<IUser>(`${this.baseApiUrl + apiRoutes.login}`, {
      email: email,
      password: password
    });
  }
  public updateUser(userData: any) {
    return this.http.post<any>(`${this.baseApiUrl + apiRoutes.profileUpdate}`, userData);
  }
  public changeUserPassword(userData: any) {
    return this.http.post(`${this.baseApiUrl + apiRoutes.userPasswordChange}`, userData);
  }

  public signUp(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(`${this.baseApiUrl + apiRoutes.signup}`, user);
  }

  public profitAdviserSignUp(userData: any) {
    return this.http.post(`${this.baseApiUrl + apiRoutes.profitAdviserSignup}`, userData);
  }
  public secondSignUp(user: any) {
    return this.http.post(`${this.baseApiUrl + apiRoutes.SecondSignUp}`, user);
  }

  public verifyAccount(token: string, userId: string) {
    let url = this.baseApiUrl + apiRoutes.verifyEmail + '?invitationToken=' + token + '&userId=' + userId;

    return this.http.post(`${url}`, {});
  }

  public getUserDetailsByUserId(userId: string): Observable<IAssessmentUserInfo> {
    return this.http.get<IAssessmentUserInfo>(`${this.baseApiUrl + apiRoutes.getUserDetailsByUserId + '?userId=' + userId}`);
  }

  public verifyAccountAsync(userId: string, invitationToken: string): Promise<IUser | null> {
    this.loader.open();

    return new Promise<IUser | null>((res) => {
      let url = this.baseApiUrl + apiRoutes.verifyEmail + '?invitationToken=' + invitationToken + '&userId=' + userId;

      this.http.post<IUser>(url, {}).subscribe({
        next: (data) => {
          this.loader.close();
          if (data && data != null) {
            let user = data as any;
            user.isEndUser = true;
            res(user);
          } else {
            res(null);
          }
        },
        error: (err) => {
          this.loader.close();
          res(null);
        },
        complete: () => {
          this.loader.close();
        }
      });
    });
  }
  public sendForgotMail(userData: any) {
    return this.http.post(`${this.baseApiUrl + apiRoutes.ForgetPassword + '?Email=' + userData.email}`, userData);
  }
  public resetPassword(userData: any) {
    return this.http.post(`${this.baseApiUrl + apiRoutes.ResetPassword}`, userData);
  }

  // api to call to send otp
  public sendOtp(data: any) {
    let url = this.baseApiUrl + apiRoutes.ForgetPassword + '?MobileNumber=' + this.encodeValue(data);

    return this.http.post(`${url}`, {});
  }

  public resendOtp(data: any) {
    this.storedNumber = sessionStorage.getItem('key');
    let url = this.baseApiUrl + apiRoutes.ResendOtp + '?UserId=' + this.storedNumber;

    return this.http.post(`${url}`, {});
  }

  // api call to otp reset
  public otpReset(otpNumber: any, UserId: string) {
    let url = this.baseApiUrl + apiRoutes.VerifyOtp + '?OtpNumber=' + otpNumber + '&UserId=' + UserId;

    return this.http.post(`${url}`, {});
  }
  public getUserDetailById(userData: any) {
    return this.http.post(`${this.baseApiUrl + apiRoutes.getUserDetailsByUserId}`, userData);
  }

  public getUserById(userId: string) {
    return this.http.get<IUser>(`${this.baseApiUrl + apiRoutes.getLoanOfficer + '?Id=' + userId}`);
  }

  public checkIfUriCorrect(uri: string) {
    return this.http.get<any>(`${this.baseApiUrl + apiRoutes.checkIfUriCorrect + '?uri=' + uri}`);
  }

  public getArchiveProfit() {
    return this.http.get<IUser[]>(`${this.baseApiUrl + apiRoutes.profitArchives}`);
  }

  public unArchiveUser(id: string) {
    return this.http.put<any>(`${this.baseApiUrl + apiRoutes.unarchive + '?id=' + id}`, {});
  }

  encodeValue(value: string): string {
    value = value.trim();
    return encodeURIComponent(value);
  }

  exportCsv(): Observable<any> {
    return this.http.get(this.baseApiUrl + apiRoutes.exportCSV, {responseType: 'blob'});
  }
}
