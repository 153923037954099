export enum apiRoutes {
  account = 'Account',
  login = 'Account/Login',
  signup = 'Account/SignUp',
  profitAdviserSignup = 'Account/SignUpAdviser',
  SecondSignUp = 'Account/SecondSignUp',
  ResetPassword = 'Account/ResetPassword',
  VerifyOtp = 'Account/VerifyOtp',
  ResendOtp = 'Account/ResendOtp',
  ForgetPassword = 'Account/ForgetPassword',
  verifyEmail = 'Account/verifyEmail',
  GetUserById = 'User/GetById',
  checkIfUriCorrect = 'Account/CheckUriCorrect',
  profileUpdate = 'user/UpdateProfile',
  userPasswordChange = 'user/ChangePassword',
  addLoanOfficer = 'user/addLoanOfficer',
  addEditProfitAdviser = 'user/AddUpdateProfitAdvisor',
  getLoanOfficer = 'User/GetById',
  unarchive = 'User/unarchive',
  profitArchives = 'User/profit/archive',
  getUserDetailByUserId = 'UserDetails/GetByUserId',
  getUserDetailsByUserId = 'UserDetails/GetUserDetailByUserId',

  CalandlySetUserEvent = 'Calandly/SetUserEvent',

  exportCSV = 'User/ExportUsersToExcel',
  //assessment routes

  //assessment routes
  saveAssessment = 'Assessment',
  GetAssessmentById = 'Assessment/GetById',
  GetAssessmentUserEventList = 'Assessment/GetUserEventList',
  GetAssessmentByUserId = 'Assessment/GetByUserID',
  GetAssessmentPdfDetail = 'Assessment/GetAssessmentPdfDetail',
  deleteAssessement = 'Assessment',
  getAssessmentQuestionsById = 'AssessmentQuestion/GetByAssessmentId',
  GetAssessmentQuestionCategory = 'AssessmentQuestionCategory/',
  GetAllCategory = 'AssessmentQuestionCategory',
  addCategory = 'AssessmentQuestionCategory',

  getAllQuestions = 'AssessmentQuestionMaster',
  addQuestion = 'AssessmentQuestionMaster',
  editQuestion = 'AssessmentQuestionMaster',
  deleteQuestion = 'AssessmentQuestionMaster',
  getQuestionByCategory = 'AssessmentQuestionMaster/GetByAssessmentQuestionCategoryId',
  getQuestionWithCategory = 'AssessmentQuestionMaster/GetQuestionWithCategory',
  getAllProfitAdviser = 'User/GetAllProfitAdviser',
  getAllAssessmentGuidance = 'AssessmentGuidance',
  deleteAssessmentGuidance = 'AssessmentGuidance',
  deleteLoanOfficer = 'User',
  User = 'User',
  agentAssign = 'User/agent/assign',

  //Inquiry Routes
  getAllInquire = 'Inquiry',
  addInquiry = 'Inquiry',
  GetByEmailId = 'Inquiry/GetByEmailId',

  // FAQ Routes
  FAQ = 'FAQ',

  // profit course
  getAllProfitPlanCourseClasses = 'Courses/GetByIdCourseAllProfitPlan',
  getAllCourse = 'Courses/GetAllCourse',

  course = 'Courses',
  plan = 'plan',
  addCourse = 'Courses/AddCourse',
  updateCourse = 'Courses/UpdateSaveCourse',
  getCourseById = '/GetByIdCourse',
  deleteCourseAndPlan = '/DeleteCourseProfit',
  downloadCourseAndPlan = '/ExportExcelAllProfitPlan',

  DeleteProfitPlan = '/DeleteProfitPlan',
  // profit plan classes
  profitPlan = '',
  addprofitPlan = '/AddProfitPlan',
  resetPassword = 'Account/ResetPassword',

  // Previous Coaching
  AddPreviousCoachingCall = '/AddPreviousCoachingCall',
  GetAllPreviousCoachingCall = '/GetAllPreviousCoachingCall',
  UpdatePreviousCoachingCall = '/UpdatePreviousCoachingCall',
  DeletePreviousCoachingCall = '/DeletePreviousCoachingCall', // parent
  AddPreviousCoachingCallPlan = '/AddPreviousCoachingCallPlan',
  UpdatePreviousCoachingCallPlan = '/UpdatePreviousCoachingCallPlan',
  GetAllPreviousCoachingCallPlanByCourseId = '/GetAllPreviousCoachingCallPlanByCourseId',
  GetPreviousCoachingCallById = '/GetPreviousCoachingCallById',
  DeletePreviousCoachingCallPlan = '/DeletePreviousCoachingCallPlan', // grid
  UpdateDisplayOrder = '/UpdateDisplayOrder',
  UpdateProfitPlanDisplayOrder = '/UpdateProfitPlanDisplayOrder',
  DeletePreviousCoachingPlanAttachmentById = '/DeletePreviousCoachingPlanAttachmentById',
  SavePreviousCoachingAttachment = '/SavePreviousCoachingAttachment',
  UpdatePreviousCoachingAttachment = '/UpdatePreviousCoachingAttachment',
  GetPreviousCoachingAttachments = '/GetPreviousCoachingAttachments',
  // host event
  AddHowToHostEventsCourses = '/AddHowToHostEventsCourses',
  GetAllHowToHostEventsCourse = '/GetAllHowToHostEventsCourse',
  GetHowToHostEventsCourseById = '/GetHowToHostEventsCourseById',
  UpdateHowToHostEventsCourse = '/UpdateHowToHostEventsCourse',
  DeleteHowToHostEventsCourse = '/DeleteHowToHostEventsCourse',

  AddHowToHostEventsPlan = 'Plan/AddHowToHostEventsPlan',
  GetAllHostEventPlanByCourseId = 'Plan/GetAllHostEventPlanByCourseId',
  GetHostEventPlanById = 'Plan/GetHostEventPlanById',
  GetHostEventAttachments = 'Plan/GetHostEventAttachments',

  //message board
  categoryList = 'MessageBox/GetAllCategories',
  GetAllChildSubjects = 'MessageBox/GetAllChildSubjects',
  SaveSubject = 'MessageBox/SaveSubject',
  UpdateSubject = 'MessageBox/UpdateSubject',
  GetAllSubjects = 'MessageBox/GetAllSubjects',
  DeleteSubject = 'MessageBox/DeleteSubject',
  SaveChildSubjects = 'MessageBox/SaveChildSubjects',
  UpdateChildSubject = 'MessageBox/UpdateChildSubject',
  DeleteChildSubjects = 'MessageBox/DeleteChildSubjects',
  SendMessage = 'MessageBox/SendMessage',
  ReceiveMessages = 'MessageBox/ReceiveMessages',
  EditMessage = 'MessageBox/EditMessage',
  DeleteMessage = 'MessageBox/DeleteMessage',
  DeleteMessageAttachment = 'MessageBox/DeleteSingleFile',
  UploadMessageFiles = 'MessageBox/UploadMessageFiles',
  GetSubjectInfoById = 'MessageBox/GetSubjectInfoById',
  ReplyToMessage = 'MessageBox/ReplyToMessage',
  // plan
  DeletePopByIdeasPlan = '/DeletePopByIdeasPlan',
  AddPopByIdeasPlan = '/AddPopByIdeasPlan',
  UpdatePopByIdeasPlan = '/UpdatePopByIdeasPlan',
  GetAllPopByIdeasPlanByCourseId = 'Plan/GetAllPopByIdeasPlanByCourseId',

  GetPopByIdeasPlanById = 'Plan/GetPopByIdeasPlanById',

  SavePopByIdeaAttachment = 'Plan/SavePopByIdeaAttachment',
  UpdatePopByIdeaAttachment = 'Plan/UpdatePopByIdeaAttachment',
  GetPopByIdeaAttachments = 'Plan/GetPopByIdeaAttachments',
  DeletePopByIdeaAttachments = 'Plan/DeletePopByIdeaAttachments',
  DeletePopByIdeaById = 'Plan/DeletePopByIdeaById',
  GetAllPopByIdeasCourse = '/GetAllPopByIdeasCourse',
  GetPopByIdeasCourseById = '/GetPopByIdeasCourseById',
  AddPopByIdeasCourse = '/AddPopByIdeasCourse',
  UpdatePopByIdeasCourse = '/UpdatePopByIdeasCourse',
  DeletePopByIdeasCourse = '/DeletePopByIdeasCourse',

  // TextingCampaign
  AddTextingCampaignPost = 'TextingCampaign/AddTextingCampaignPost',
  GetAllTextingCampaignPost = 'TextingCampaign/GetAllTextingCampaignPost',
  UpdateTextingCampaignPost = 'TextingCampaign/UpdateTextingCampaignPost',
  DeleteTextingCampaignPost = 'TextingCampaign/DeleteTextingCampaignPost',
  TCUpdateDisplayOrder = 'TextingCampaign/UpdateDisplayOrder',

  //SMS/EMailDunning
  SMSEmailDunning = 'SMSEmailDunning/SMSEmailDunning'
}
